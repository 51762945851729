<template lang="pug">
  .table-layout
    table.table.table--cell-overflow-hidden.table--cell-overflow-visible
      thead.table__header
        tr.table__row.table__row--header
          th.table__cell {{ 'pages.settings.number_silage' | translate }}
          th.table__cell {{ 'base.housing' | translate }}
          th.table__cell {{ 'base.ration' | translate }}
          th.table__cell {{ 'base.capacity' | translate }}
          th.table__cell(v-html="$t('pages.settings.weight_moment')")
          th.table__cell(v-html="$t('pages.settings.planning_day')")
      v-table-body(
        :cols="5"
        :loading="loading"
        :empty="!silages.length")
        silage-table-row(
          v-for="silage in silages"
          :key="silage.id"
          :silage="silage")
      tfoot.table__footer(
        v-if="!loading && showPagination")
        tr.table__row.table__row--footer
          td.table__cell(colspan="5")
            .table__pagination
              v-paginate(
                v-model="page"
                :page-count="pageCount"
                :click-handler="pageChangeHandler")
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import paginationMixin from '@/mixins/pagination.mixin'
  import SilageTableRow from './SilageTableRow'

  export default {
    name: 'SilageTable',

    mixins: [paginationMixin],

    components: {
      SilageTableRow
    },

    mounted () {
      this.setupPagination(this.fetchSilages)
    },

    methods: {
      ...mapActions(['fetchSilages'])
    },

    computed: {
      ...mapGetters([
        'silages',
        'silagesFilters',
        'sortedSilages'
      ]),

      filters () {
        return this.silagesFilters
      }
    },

    watch: {
      filters () {
        this.page = 1
        this.loadData()
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
