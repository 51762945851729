<template lang="pug">
  v-card
    v-card-title
      h2.card-title {{ 'modals.silage.settings.title' | translate }}
    v-card-content
      v-select.mb-3(
        :label="$t('base.housing')"
        :options="housingsList"
        v-model="form.housing")
      v-select.mb-3(
        :label="$t('base.ration')"
        :options="rationsList"
        v-model="form.ration_id")
      v-text-field.mb-3(
        :label="$t('pages.settings.number_silage')"
        v-model="form.number")
      v-text-field.mb-3(
        :label="$t('base.capacity')"
        v-model="form.capacity")
      v-text-field.mb-3(
        :label="$t('pages.settings.weight_moment_mobile')"
        v-model="form.currentlyWeight")
      v-text-field(
        :label="$t('base.note')"
        v-model="form.info")
    v-card-actions
      v-btn(
        :disabled="!isNumberValid || !isHousingSelected || !isСapacityValid || !isСurrentlyWeightValid || !isRationSelected"
        :loading="loading"
        @click="onUpdate") {{ 'actions.save' | translate }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SettingsModal',

  props: {
    item: Object
  },

  data: () => ({
    form: {
      number: null,
      housing: null,
      capacity: null,
      info: null,
      currentlyWeight: null,
      ration_id: null
    },
    loading: false
  }),

  computed: {
    ...mapGetters([
      'housingsList',
      'rationsList'
    ]),

    isNumberValid () {
      return !!this.form.number
    },

    isHousingSelected () {
      return this.form.housing !== null
    },

    isСapacityValid () {
      return this.form.capacity === 0
        ? !this.form.capacity
        : !!this.form.capacity
    },

    isСurrentlyWeightValid () {
      return this.form.currentlyWeight === 0
        ? !this.form.currentlyWeight
        : !!this.form.currentlyWeight
    },

    isRationSelected () {
      return this.form.ration_id !== null
    }
  },

  async mounted () {
    await this.fetchHousingsList()
    await this.fetchRationsList()
    this.form.housing = this.item.housing_id
    this.form.number = this.item.number
    this.form.capacity = this.item.max_weight
    this.form.currentlyWeight = this.item.net_weight
    this.form.info = this.item.comment
    this.form.ration_id = this.item.ration_id
  },

  methods: {
    ...mapActions([
      'fetchHousingsList',
      'fetchRationsList',
      'updateSilage'
    ]),

    async onUpdate () {
      this.loading = true
      const payload = {
        housing_id: this.form.housing,
        number: this.form.number,
        max_weight: +this.form.capacity,
        net_weight: +this.form.currentlyWeight,
        comment: this.form.info,
        ration_id: this.form.ration_id
      }
      const { data, error } = await this.updateSilage({ id: this.item.id, payload })
      this.loading = false
      if (error) {
        for (const key in data.errors) {
          this.$toasted.show(data.errors[key], {
            position: 'bottom-center',
            duration: 5000
          })
        }
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
