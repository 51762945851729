<template lang="pug">
  tr.table__row.table__row--body
    td.table__cell {{ silage.number }}
    td.table__cell {{ silage.housing_name }}
    td.table__cell {{ silage.ration_name }}
    td.table__cell {{ silage.max_weight }}
    td.table__cell {{ silage.net_weight }}
    td.table__cell
      .table__cell-wrapper
        p.table__cell-text {{ silage.planned_weight_for_day }}

        v-btn(
          icon
          v-permission-disable="permissions.update_silage"
          @click="modals.showSettings = true"
        ).table__button.table__button--gap-right
          icon-gear.table__settings

        v-dialog(
          max-width="348"
          v-model="modals.showSettings"
        )
          settings-modal(
            :item="silage"
            @close="modals.showSettings = false"
          )
        v-info(
          dark)
          p.table__tooltip {{ silage.comment || 'messages.no_note' | translate }}

</template>

<script>
  import IconGear from '@/components/icons/IconGear'
  import SettingsModal from './modals/SettingsModal'
  import permissions from '@/util/permissions'

  export default {
    name: 'SilageTableRow',

    components: {
      IconGear,
      SettingsModal
    },

    props: {
      silage: {
        type: Object,
        required: true
      }
    },

    data: () => ({
      modals: {
        showSettings: false
      },
      permissions: permissions
    }),

    computed: {
      dataTooltip () {
        const messageEmpty = this.$t('messages.no_note')
        return this.silage.comment
          ? this.silage.comment.split(',')
          : messageEmpty.split(',')
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
